/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carter+One&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track {
  background: #000;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    93.5deg,
    #f89947 0%,
    #e730a8 31.67%,
    #7327d7 66.07%,
    #0a76eb 100%
  );
}
body {
  background-color: #000001 !important;
}
@mixin para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.005em;
  color: #858c94;
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar-toggler-icon {
  width: 24px !important;
  height: 17px !important;
  background-image: none !important;
  position: relative !important;
  border-bottom: 3px solid #fff !important;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar {
  background: rgba(7, 7, 7, 0.75);
  /* Neutral/Neutral 01 */

  border-bottom: 1px solid #2c3a4b;
  backdrop-filter: blur(25px);
}
.nav-btn {
  padding: 8px 16px;
  gap: 10px;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  /* identical to box height, or 24px */
  letter-spacing: 0.008em;

  /* Neutral/Neutral 05 */

  color: #858c94;
  /* Neutral/Neutral 05 */

  border: 0.5px solid #858c94;
  border-radius: 100px;
  svg {
    font-size: 20px;
  }
}
.nav-link {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 170%;
  color: #e1e1e2 !important;
}
.btns {
  animation: btns 2s ease-in-out 1s infinite normal forwards;
}
@keyframes btns {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.hero-bg {
  video {
    width: 100% !important;
    object-fit: cover;
    height: 90vh;
  }
  .overlay {
    background: linear-gradient(180deg, rgba(0, 0, 1, 0) 58.29%, #000001 100%);
    // mix-blend-mode: normal;
    position: absolute;
    bottom: -2% !important;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  .hero-content {
    position: absolute;
    top: 15%;
    // left: 12%;
    width: 100% !important;
    text-align: center;
  }
  h1 {
    font-family: "Carter One", cursive;
    font-style: normal;
    font-size: 64px;
    line-height: 116%;
    color: #ffffff;
    // background: linear-gradient(to right, #ffffff, #7878cd);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // animation: gradient 1s ease infinite;
  }

  button {
    background: linear-gradient(
      93.5deg,
      #f89947 0%,
      #e730a8 31.67%,
      #7327d7 66.07%,
      #0a76eb 100%
    );
    backdrop-filter: blur(12.5px);
    border-radius: 6px;
    padding: 10px 20px;
    border: none;
    margin-top: 10px;
    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 170%;
      text-transform: uppercase;
      color: #fff;
    }
  }
  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  p {
    @include para();
    font-size: 16px;
    width: 879px;
    margin: auto;
  }
}
@keyframes gradient {
  0% {
    background: linear-gradient(to right, #ffffff, #7878cd);
  }
  100% {
    background: linear-gradient(to left, #ffffff, #7878cd);
  }
}
.sec1-bg {
  // background: linear-gradient(360deg, #070707 0%, rgba(18, 21, 90, 0.75) 100%);
  .card {
    background-color: transparent !important;
    width: 461px;
    img {
      width: 450px;
    }
    .card-body {
      padding: 10px 0px !important;
      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 130%;
        text-transform: uppercase;
        color: #dadee3;
        padding-top: 10px;
      }
      p {
        @include para();
      }
      a {
        font-family: "JetBrains Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #0063f7;
      }
      button {
        background-color: rgba(44, 58, 75, 0.5) !important;
        border-radius: 6px;
        padding: 6px 14px;
        border: none;
        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 170%;
          text-align: center;
          text-transform: uppercase;
          background: linear-gradient(90deg, #fa359e 0%, #811cd4 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }
}

.sec1-btn {
  background: rgba(45, 67, 107, 0.5);
  backdrop-filter: blur(12.5px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 10px 20px;
  border-radius: 12px;
  border: none;
  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 170%;
    text-transform: uppercase;

    /* Gradient/3 */

    background: linear-gradient(
      93.5deg,
      #f89947 0%,
      #e730a8 31.67%,
      #7327d7 66.07%,
      #0a76eb 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.circle {
  background: url(./media/Circle.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 192px;
  width: 234px;
  margin-top: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.sec1-box {
  margin-top: 40px;
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #ffffff;
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #dadee3;
  }
}

.sec2 {
  height: 700px;
  background: rgba(9, 7, 16, 0.5) !important;
  position: relative;
  video {
    width: 100% !important;
    height: 700px;
    object-fit: cover;
    // fill: rgba(9, 7, 16, 0.5);
  }
  .content {
    background: linear-gradient(
      95.57deg,
      rgba(248, 153, 71, 0.15) 0.22%,
      rgba(231, 48, 168, 0.15) 31.81%,
      rgba(115, 39, 215, 0.15) 66.14%,
      rgba(10, 118, 235, 0.15) 100%
    );
    backdrop-filter: blur(25px);
    border-radius: 16px;
    position: absolute;
    top: 25%;
    left: 12%;
    padding: 40px;
  }
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    text-transform: uppercase;
    color: #ffffff;
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    color: #a5abb3;
    width: 1000px;
    margin: auto;
  }
}

.toko {
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #ffffff;
    margin-top: 10px;
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #858c94;
  }
  .toko-textEnd {
    text-align: end;
  }
}
.toko-video-bg {
  //   background: linear-gradient(90deg, #FA359E 0%, #811CD4 100%);
  // // opacity: 0.15;
  // width: 280px;
  // height: 280px;
  border: 4px solid #fa359e6a;
  width: 260px;
  height: 257px;
  margin: auto;
  border-radius: 140px;
  video {
    border-radius: 140px;
    object-fit: cover;
  }
}

.roadmap {
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.1em;

    color: #a5abb3;
  }
}

.roadmap-box {
  background: linear-gradient(
    201.41deg,
    rgba(108, 43, 158, 0.15) 11.6%,
    rgba(67, 19, 104, 0.144) 93.43%
  );
  backdrop-filter: blur(5px);
  margin-bottom: 100px !important;
  position: relative;
  z-index: 2;
  h1 {
    font-weight: 600;
    font-size: 24px;
  }
  p {
    font-weight: 400;
  }
}
.number {
  background: linear-gradient(90deg, #fa359e 0%, #811cd4 100%);
  border-radius: 100px;
  width: 124px;
  height: 124px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: #ffffff;
  }
}

.about-bg {
  height: 1430px;
  // background: url(./media/about.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .about-content {
    position: absolute;
    top: 0;
    left: 8%;
    background: linear-gradient(
      176.72deg,
      #020206 2.71%,
      rgba(0, 0, 0, 0.33) 48.03%,
      #020202 97.29%
    );
  }
}

a {
  text-decoration: none !important;
}
.caro-item {
  width: 460px;
  background: linear-gradient(
    111.68deg,
    rgba(17, 23, 40, 0.15) 7.59%,
    rgba(49, 82, 165, 0.3) 102.04%
  );
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #a5abb3;
    // text-transform: lowercase !important;
  }
}
.react-multi-carousel-list {
  width: 950px !important;
  margin: auto;
}
.article-bg {
  background: linear-gradient(
    111.68deg,
    rgba(17, 23, 40, 0.15) 7.59%,
    rgba(49, 82, 165, 0.3) 102.04%
  );
  backdrop-filter: blur(10px) !important;
  border-radius: 12px !important;
  width: 360px;
  margin: auto;
  svg {
    color: #7a74ff 111.67% !important;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #dadee3;
    span {
      color: #7a74ff;
    }
  }
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    color: #f4f6f9;
  }
}
.swiper {
  width: 950px !important;
}
.swiper-scrollbar {
  background-color: #2c3a4b !important;
  width: 150px !important;
  margin-top: 30px !important;
  position: relative !important;
  // top: 20% !important;
  margin: auto;
  display: block;
}
.swiper-scrollbar-drag {
  // background-color: red !important;
  background: linear-gradient(90deg, #fa359e 0%, #811cd4 100%) !important;
  width: 50px !important;
}
.swiper-pagination {
  display: none !important;
}

.footer-bg {
  padding-top: 30px;
  video {
    object-fit: cover;
    width: 100%;
    height: 410px;
    position: absolute;
    bottom: 2;
    left: 0%;
  }
  .footer-ab-content {
    position: absolute;
    backdrop-filter: blur(7px);
    width: 100% !important;
    background: linear-gradient(180deg, #000001 0%, rgba(0, 0, 1, 0.44) 100%);
    left: 0;
    // right: 0
    padding: 0px 40px;
  }
  h3 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-transform: uppercase;
    color: #dadee3;
  }
  li {
    a {
      font-family: "poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.005em;
      color: #858c94;
    }
  }
  svg {
    color: #a5abb3;
  }
  hr {
    height: 2px;
    background-color: #a5abb3;
    margin-top: 8px;
  }
  p {
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #545d69;
  }
}

.section-roadmap .slider {
  /* -ms-overflow-style: none !important; */
  display: flex;
  flex: 1;
  margin-top: 70px;
  margin-bottom: 70px;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  /* scrollbar-width: none !important; */
  -webkit-overflow-scrolling: auto !important;
}

.section-roadmap .slider::-webkit-scrollbar {
  background: #2c3a4b;
  border-radius: 15px;
  height: 5px;
  margin: 10px !important;
}
.section-roadmap .slider::-webkit-scrollbar-thumb {
  background: #f700ff;
  border-radius: 15px;
}

.section-roadmap .slider .slider-inner {
  align-items: stretch;
  display: flex;
}

.section-roadmap .slider .slider-inner .roadmap-item {
  border: 1px solid #e730a8;
  border-radius: 24px;
  margin: 0 16px;
  max-width: calc(100vw - 32px);
  padding: 16px;
  position: relative;
  width: 480px;
}

.section-roadmap .slider .slider-inner .roadmap-item:before {
  border-top: 1px solid #fa359e;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-100%, -50%);
  width: 32px;
}

.section-roadmap .slider .slider-inner .roadmap-item:after {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #7327d7;
  color: #7327d7;
  content: attr(data-status);
  display: inline-block;
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 74px;
  opacity: 0.6;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(calc(50% - 24px), -50%) rotate(-90deg);
  width: 500px;
}

.section-roadmap .slider .slider-inner .roadmap-item.in-progress:after {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffff;
  color: #ffff;
  content: attr(data-status);
  display: inline-block;
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 74px;
  opacity: 0.6;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(calc(50% - 24px), -50%) rotate(-90deg);
  width: 500px;
}

.section-roadmap .slider .slider-inner .roadmap-item:first-of-type:before {
  display: none;
}

.section-roadmap .slider .slider-inner .roadmap-item .item-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  color: #fff;
  font-family: "Poppins";
}

.section-roadmap .slider .slider-inner .roadmap-item .item-tasks {
  margin-top: 16px;
  color: #fff;
}

.section-roadmap .slider .slider-inner .roadmap-item .item-tasks small {
  color: #bdbdbd;
}

.section-roadmap .slider .slider-inner .roadmap-item .item-tasks .item-task {
  padding: 4px 24px;
  position: relative;
}

.section-roadmap
  .slider
  .slider-inner
  .roadmap-item
  .item-tasks
  .item-task:before {
  content: "";
  display: block;
  height: 20px;
  left: -5px;
  position: absolute;
  top: 6px;
  border-radius: 100px !important;

  width: 20px;
}

.section-roadmap
  .slider
  .slider-inner
  .roadmap-item
  .item-tasks
  .item-task.wait-for-it:before {
  background: url("./media/check1.svg") no-repeat 50% !important;
  // filter: brightness(0) invert(1);
}
.section-roadmap
  .slider
  .slider-inner
  .roadmap-item
  .item-tasks
  .item-task.done:before {
  background: url("./media/check.svg") no-repeat 50% !important;
  // filter: brightness(0) invert(1);
}
.section-roadmap
  .slider
  .slider-inner
  .roadmap-item
  .item-tasks
  .item-task.in-progress:before {
  background: url("./media/check1.svg") no-repeat 50% !important;
  filter: brightness(0) invert(1);
}
.section-roadmap .slider .slider-inner .roadmap-item.in-progress {
  background: linear-gradient(-90deg, #fa359e 0%, #811cd4 100%) !important;
}

.section-roadmap .slider .slider-inner .roadmap-item.in-progress .item-task {
  font-weight: 500;
}

.section-roadmap .slider .slider-inner .roadmap-item.in-progress .matrix-bg {
  background-size: cover;
  bottom: 0;
  height: 275px;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 133px;
}

.section-roadmap .slider .slider-inner .roadmap-item.wait-for-it:before {
  border-top: 1px dashed #fa359e;
}

.section-roadmap .slider .slider-inner .roadmap-item.wait-for-it .item-task {
  font-weight: 300;
}
.section-roadmap p.desc {
  font-weight: 400;
  margin: 0;
  font-size: 1em;
  letter-spacing: 8px;
  line-height: 0;
  color: #fff;
}

.faq-row {
  margin: 20px auto !important;
  padding-left: 20px !important;
  background: linear-gradient(
    111.68deg,
    rgba(17, 23, 40, 0.15) 7.59%,
    rgba(49, 82, 165, 0.3) 102.04%
  );
  backdrop-filter: blur(10px) !important;
  border-radius: 15px;
  border-bottom: none !important;
  width: 75% !important;
}

.blockmover-row2 {
  h1 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    text-transform: uppercase;
    color: #dadee3;
  }
  p {
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 170%;
    text-transform: uppercase;
    color: #dadee3;
  }
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Timer
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.timer h1 {
  display: inline-block;
  padding-left: 15px;
  text-align: center;
}
#seconds {
  animation: myAnim 4s linear 2s infinite normal forwards;
}
@keyframes myAnim {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.timer {
  float: none;
  margin: 0 auto;
  width: 50% !important;
  border-radius: 10px;
  h3 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 170%;
    color: #fa3535;
  }
}

.timer small {
  font-weight: 300 !important;
  font-size: 11px !important;
  color: rgb(255, 255, 255) !important;
}
.timer strong {
  color: rgb(255, 255, 255);
  font-size: 44px !important;
}
@media only screen and (max-width: "600px") {
  .timer {
    width: 100% !important;
  }
  .timer small {
    font-weight: 300 !important;
    font-size: 9px !important;
  }
  .timer strong {
    font-size: 29px !important;
  }
}

@media only screen and (max-width: "600px") {
  .hero-bg {
    .overlay {
      bottom: -5% !important;
    }
  }
  .hero-content {
    h2 {
      font-size: 14px;
    }
    h1 {
      font-size: 28px;
    }
    p {
      width: 100%;
      font-size: 16px;
    }
  }
  .res-video {
    height: 200px !important;
  }
  .res-video1 {
    display: none;
  }
  .toko-textEnd {
    text-align: start !important;
  }
  .sec1-bg {
    .card {
      width: 100%;
      img {
        width: 100%;
      }
      .card-body {
        h1 {
          font-size: 22px;
        }
      }
    }
  }
  .sec2 {
    .content {
      position: static;
      width: 100% !important;
      h1 {
        font-size: 20px;
      }
      p {
        width: 100%;
        font-size: 16px;
      }
    }
  }
  .mySwiper {
    display: none !important;
  }
  .article-bg {
    width: 100%;
  }
  .sec2 {
    height: auto;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .res-col {
    text-align: start !important;
  }
  .aabout-bg-shadow {
    // display: none !important;
  }
  .about-bg {
    height: inherit !important;
    .about-content {
      position: static !important;
    }
  }
}
